var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp && !_vm.isInvitedUser
        ? _c("v-row", { staticClass: "mt-8 personal-details" }, [
            _c(
              "span",
              {
                staticClass:
                  "text-h5 mx-auto grey-darken-4--text font-weight-bold",
              },
              [_vm._v(_vm._s(_vm.$t("personalDetailsNew")))]
            ),
          ])
        : _c("v-row", {}, [
            _c(
              "span",
              {
                staticClass:
                  "text-h5 mx-auto grey-darken-4--text font-weight-bold",
              },
              [_vm._v(_vm._s(_vm.$t("personalDetailsNew")))]
            ),
          ]),
      _c(
        "v-row",
        { staticClass: "mt-6" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                cols: "12",
                sm: "9",
                md: "9",
                lg: "9",
                "space-around": "",
              },
            },
            [
              !_vm.isInvitedUser
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", {
                        staticClass: "mx-auto",
                        attrs: { cols: "12", sm: "10", md: "12", lg: "12" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                cols: "12",
                                sm: "10",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "error-messages": _vm.nameErrors,
                                  label: _vm.$t("fullName"),
                                  disabled: _vm.isInvitedUser,
                                  outlined: "",
                                  required: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.name.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                cols: "12",
                                sm: "10",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "error-messages": _vm.emailErrors,
                                  label: _vm.$t("emailAddress"),
                                  disabled: _vm.isInvitedUser,
                                  outlined: "",
                                  required: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.email.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.email.$touch()
                                  },
                                  keypress: function ($event) {
                                    _vm.isEmailAvailable = true
                                  },
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                cols: "12",
                                sm: "10",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "error-messages": _vm.phoneErrors,
                                  label: _vm.$t("phoneNumber"),
                                  disabled: _vm.isInvitedUser,
                                  outlined: "",
                                  required: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.phone.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.phone.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.phone,
                                  callback: function ($$v) {
                                    _vm.phone = $$v
                                  },
                                  expression: "phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto mb-8",
                              attrs: { cols: "12", sm: "10", lg: "7", md: "7" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 text-none rounded-lg",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    disabled:
                                      _vm.$v.$invalid || _vm.isContinueClicked,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("continue")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }