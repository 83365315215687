<template>
  <v-card elevation="0" width="100%">
    <v-card-text :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-0'">
      <v-row no-gutters>
        <v-col cols="12" md="6" xl="6" lg="6" sm="12">
          <v-card :elevation="$vuetify.breakpoint.mdAndUp ? 2 : 0">
            <v-card-title class="px-7"> Service Plan </v-card-title>
            <v-card-text>
              <v-row no-gutters class="my-4 px-3">
                <span class="my-auto">{{ $t("plan") }} </span>
                <v-spacer></v-spacer>
                <v-col cols="6" md="5" lg="5" class="d-flex justify-end">
                  <span
                    v-if="selectedSubscription.is_custom"
                    class="text-subtitle-1 font-weight-medium"
                    >{{ selectedSubscription.sub_name }}</span
                  >
                  <v-select
                    v-if="!selectedSubscription.is_custom"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-8' : 'ml-4'"
                    v-model="selectedSubscription"
                    :items="subscriptions"
                    label=""
                    item-text="sub_name"
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    hide-details
                    outlined
                    return-object
                    @change="changePlan"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3"
                ><span class="my-auto">Choose Your Team Size </span
                ><v-tooltip
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="250"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >maximum of 14 users may be selected, if they want to add
                    more users they may do so after the company is created from
                    the company settings screen</span
                  > </v-tooltip
                ><v-spacer></v-spacer>
                <v-col cols="4" sm="4" md="5" lg="5" xl="5" class="ml-4">
                  <v-select
                    v-model="users"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-8' : 'ml-4'"
                    :items="usersList"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    required
                    dense
                    hide-details
                    label="users"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-title class="grey--text text--darken-4">
                Additional Services
              </v-card-title>
              <div>
                <v-row class="mb-5" justify="end">
                  <v-card
                    :width="$vuetify.breakpoint.mdAndUp ? '98%' : '98%'"
                    elevation="0"
                    class="excavator-type-style mt-6"
                    color="#FAFAFA"
                    :disabled="
                      selectedSubscription.price == 0 &&
                      companyCenters.length >= 1
                    "
                  >
                    <v-card-title class="font-weight-medium body-2">
                      {{ $t("add811Center") }}
                    </v-card-title>
                    <v-container class="px-0">
                      <v-row no-gutters>
                        <v-col cols="12" lg="6" md="6" class="px-3">
                          <v-select
                            v-model="selectedCenter"
                            :items="select811CenterOptions"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            required
                            :label="$t('811Center')"
                            item-text="center_name"
                            item-value="center_id"
                            :disabled="
                              (selectedSubscription.price == 0 &&
                                companyCenters.length >= 1) ||
                              allCentersSelected
                            "
                            @change="onCenterChange"
                            @blur="onCenterChange"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" class="px-3">
                          <v-select
                            v-model="excavatorType"
                            :items="excavatorTypes"
                            :disabled="disableExcavatorTypeSelection"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            required
                            :label="$t('excavatorType')"
                            item-value="text"
                          ></v-select>
                        </v-col>
                        <v-row no-gutters class="ml-3" justify="end">
                          <v-btn
                            class="mr-4 text-none rounded-lg"
                            color="primary"
                            :disabled="!selectedCenter || !excavatorType"
                            @click="addCenter"
                          >
                            Add
                          </v-btn>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-row>
                <v-row
                  v-if="
                    $vuetify.breakpoint.mdAndUp && companyCenters.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    class="pb-0 caption font-weight-medium grey--text text--darken-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : 'pl-7'"
                    >{{ $t("811Center") }}</v-col
                  >
                  <v-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    class="pb-0 caption font-weight-medium grey--text text--darken-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : null"
                    >{{ $t("excavatorType") }}</v-col
                  >
                  <v-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    class="text-center pb-0 caption font-weight-medium grey--text text--darken-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : null"
                    >{{ $t("delete") }}</v-col
                  >
                </v-row>
                <v-row
                  v-if="
                    $vuetify.breakpoint.mdAndUp && companyCenters.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    xl="12"
                    md="12"
                    lg="12"
                    :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
                    ><v-divider></v-divider></v-col
                ></v-row>
                <div v-for="(value, index) in companyCenters" :key="index">
                  <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mx-0">
                    <v-col
                      cols="12"
                      xl="4"
                      md="4"
                      lg="4"
                      class="pb-0 caption pl-4"
                    >
                      {{ value.center_name }}
                    </v-col>
                    <v-col
                      cols="12"
                      xl="4"
                      md="4"
                      lg="4"
                      class="pb-0 caption pl-2"
                      >{{ value.excavator_type }}</v-col
                    >
                    <v-col
                      cols="12"
                      xl="4"
                      md="4"
                      lg="4"
                      class="pb-0 text-center caption pl-2"
                    >
                      <v-btn
                        icon
                        aria-label="delete"
                        @click="deleteCenter(value)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="mx-0 px-0 mt-3"
                  >
                    <v-col cols="10">
                      <v-row
                        class="pb-0 caption grey--text text--darken-4 px-0"
                      >
                        {{ value.center_name }}
                      </v-row>
                      <v-row
                        class="pb-0 caption pa-0 grey--text text--darken-4"
                        >{{ value.excavator_type }}</v-row
                      >
                    </v-col>
                    <v-col
                      cols="2"
                      class="pb-0 text-center caption pa-0 grey--text text--darken-4"
                    >
                      <v-btn
                        icon
                        aria-label="delete"
                        @click="deleteCenter(value)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 px-0">
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      lg="12"
                      class="mx-0 pl-0"
                      :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
                      ><v-divider></v-divider></v-col
                  ></v-row>
                </div>
              </div>
              <v-row
                v-if="selectedSubscription.price > 0"
                class="mt-2"
                :class="$vuetify.breakpoint.mdAndUp ? 'pl-6 pr-4' : 'pl-4 pr-2'"
              >
                <v-card elevation="0" width="80">
                  <span
                    class="caption grey--text text--darken-1 my-auto pa-0"
                    >{{ $t("additionalAttachmentsStorage") }}</span
                  >
                </v-card>
                <v-spacer></v-spacer>
                <v-col cols="6" md="5" lg="5">
                  <v-select
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0'"
                    v-model="planStorage"
                    :items="storage"
                    :placeholder="
                      planStorage && Object.keys(planStorage).length > 0
                        ? ''
                        : 'Select'
                    "
                    item-text="storage_limit"
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    hide-details
                    outlined
                    return-object
                  >
                    <template slot="item" slot-scope="data">
                      {{ getStorageText(data.item.storage_limit) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ getStorageText(item.storage_limit) }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-card-title
                class="grey--text text--darken-4 mt-4"
                v-show="selectedSubscription.price > 0"
              >
                Payment
              </v-card-title>
              <v-row
                no-gutters
                class="mt-0"
                v-show="selectedSubscription.price > 0"
              >
                <v-col cols="12" class="pa-0">
                  <v-radio-group v-model="payment_method" class="mt-0" row>
                    <v-card
                      height="100%"
                      width="100%"
                      elevation="0"
                      rounded="lg"
                      outlined
                    >
                      <v-card-title>
                        <v-radio value="creditCard"></v-radio> Credit Card
                      </v-card-title>
                      <v-card-text>
                        <v-card
                          height="100%"
                          width="100%"
                          elevation="0"
                          rounded="lg"
                          outlined
                        >
                          <div id="card-element" class="ml-2 my-1" />
                        </v-card>
                      </v-card-text>
                    </v-card>
                    <!-- <v-card
                      height="100%"
                      width="100%"
                      elevation="0"
                      rounded="lg"
                      class="mt-2"
                      outlined
                    >
                      <v-card-title>
                        <v-radio value="invoice"></v-radio>
                        Invoice
                      </v-card-title>
                    </v-card> -->
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
          sm="12"
          :class="$vuetify.breakpoint.mdAndUp ? 'pl-4' : ''"
        >
          <v-card :elevation="$vuetify.breakpoint.mdAndUp ? 2 : 0">
            <v-card-title> Order Summary </v-card-title>
            <v-card-text>
              <v-row no-gutters class="text-subtitle-1 font-weight-medium">
                Subscription
              </v-row>
              <v-row no-gutters>
                <v-col cols="6"> Plan Cost </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    selectedSubscription.base_price
                      ? selectedSubscription.base_price
                      : 0
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  {{ users }} users x ${{
                    selectedSubscription.price ? selectedSubscription.price : 0
                  }}
                  x 12 months
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    selectedSubscription.price
                      ? selectedSubscription.price * 12 * users
                      : 0
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters class="text-subtitle-1 font-weight-medium mt-2">
                Centers
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  {{
                    companyCenters.length == 0 ? 0 : companyCenters.length - 1
                  }}
                  centers x ${{
                    selectedSubscription.center_stripe_unit_amount / 12
                      ? selectedSubscription.center_stripe_unit_amount / 12
                      : 0
                  }}
                  x 12 months
                  <v-tooltip
                    :right="!$vuetify.breakpoint.smAndDown"
                    :bottom="$vuetify.breakpoint.smAndDown"
                    max-width="250"
                    class="pa-0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      You can add one center for free. Additional centers are
                      available for a nominal price.
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    selectedSubscription.center_stripe_unit_amount
                      ? selectedSubscription.center_stripe_unit_amount *
                        (companyCenters.length > 0
                          ? companyCenters.length - 1
                          : 0)
                      : 0
                  }}
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="text-subtitle-1 font-weight-medium mt-2"
                v-if="
                  planStorage &&
                  Object.keys(planStorage).length > 0 &&
                  selectedSubscription.price > 0
                "
              >
                Attachments
              </v-row>
              <v-row
                no-gutters
                v-if="
                  planStorage &&
                  Object.keys(planStorage).length > 0 &&
                  selectedSubscription.price > 0
                "
              >
                <v-col cols="6">
                  {{ getStorageText(planStorage.storage_limit) }}
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{ planStorage.price / 100 }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-divider></v-divider>
              </v-row>
              <v-row no-gutters class="my-4">
                <v-col cols="6" class="text-subtitle-1 font-weight-medium">
                  Total Due Today
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    parseInt(
                      selectedSubscription.price
                        ? selectedSubscription.price * 12 * users
                        : 0
                    ) +
                    parseInt(
                      selectedSubscription.base_price
                        ? selectedSubscription.base_price
                        : 0
                    ) +
                    parseInt(
                      planStorage &&
                        planStorage.price &&
                        selectedSubscription.price > 0
                        ? planStorage.price / 100
                        : 0
                    ) +
                    parseInt(
                      selectedSubscription.center_stripe_unit_amount
                        ? selectedSubscription.center_stripe_unit_amount *
                            (companyCenters.length > 0
                              ? companyCenters.length - 1
                              : 0)
                        : 0
                    )
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters class="text-subtitle-1 font-weight-medium">
                Your subscription will renew annually, and you'll be charged
                this amount unless you make changes or cancel
              </v-row>
              <v-row no-gutters class="my-4">
                <v-col cols="6" class="text-subtitle-1 font-weight-medium">
                  Plan end date
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  {{ planEndDate }}
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="mt-4 mb-8"
                style="width: 10rem; margin: auto"
              >
                <v-btn
                  block
                  color="primary"
                  class="text-none rounded-lg"
                  @click="submit"
                  :disabled="isDisabled"
                >
                  Purchase
                  <v-progress-circular
                    v-if="purchaseInProgress"
                    indeterminate
                    size="20"
                    color="white"
                    class="ml-2"
                  ></v-progress-circular>
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import UserData from "../../../mixins/UserData.vue";
import { loadStripe } from "@stripe/stripe-js";

export default {
  mixins: [UserData],
  data() {
    return {
      stripe: {},
      elements: {},
      paymentElement: "",
      onAddCenter: true,
      selectedCenter: null,
      excavatorType: null,
      companyCenters: [],
      planStorage: {},
      payment_method: "creditCard",
      center_stripe_product_id: "price_1OexR4SBlarpo05O3mlnoPpR",
      storage: [],
      users: 1,
      usersList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      selectedSubscription: "",
      clientSecret: "",
      purchaseInProgress: false,
      isCreditCardDetailsValid: false,
    };
  },
  async mounted() {
    const publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    this.stripe = await loadStripe(publishableKey);
    const { clientSecret } = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/setup-intent`
    ).then((res) => res.json());

    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.clientSecret = clientSecret;
    if (parseInt(this.selectedSubscription.price) > 0) {
      this.elements = this.stripe.elements({ clientSecret });

      this.paymentElement = this.elements.create("card", { style: style });
      this.paymentElement.mount("#card-element");
      this.paymentElement.on("change", (event) => {
        const { complete, empty } = event;
        this.isCreditCardDetailsValid = complete && !empty;
      });
    }
  },
  computed: {
    priceMap() {
      let pricing = {};
      if (this.$store.state.Subscription.subscriptionProducts.prices)
        this.$store.state.Subscription.subscriptionProducts.prices.forEach(
          (element) => {
            pricing[element.product] = element;
          }
        );
      return pricing;
    },
    subscriptions() {
      if (this.$store.state.Subscription.subscriptionProducts.subscriptions)
        this.$store.state.Subscription.subscriptionProducts.subscriptions.forEach(
          (element) => {
            let center;
            if (element.products)
              center =
                this.$store.state.Subscription.subscriptionProducts.prices.find(
                  (item) => item.product === element.products.center
                );
            if (center) {
              element.center_stripe_price_id = center.id;
              element.center_stripe_unit_amount = center.unit_amount / 100;
            }
            let baseProduct;
            if (element.products)
              baseProduct =
                this.$store.state.Subscription.subscriptionProducts.prices.find(
                  (item) => item.product === element.products.baseProduct
                );
            if (baseProduct) {
              element.stripe_price_id = baseProduct.id;
              element.base_price = baseProduct?.tiers[0].flat_amount / 100;
              element.price = baseProduct?.tiers[0].unit_amount / 100 / 12;
            }
          }
        );
      return this.$store.state.Subscription.subscriptionProducts.subscriptions
        ? this.$store.state.Subscription.subscriptionProducts?.subscriptions.filter(
            (element) => {
              return !element.is_custom;
            }
          )
        : [];
    },
    planEndDate() {
      const today = new Date();
      const nextYear = new Date(today);
      nextYear.setFullYear(today.getFullYear() + 1);
      const formattedDate = `${(nextYear.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${nextYear
        .getDate()
        .toString()
        .padStart(2, "0")}/${nextYear.getFullYear()}`;
      return formattedDate;
    },
    centerData() {
      return this.$store.state.User.centers;
    },
    select811CenterOptions() {
      return this.centerData.map(({ center_id, center_name }, index) => {
        if (
          !this.companyCenters.find(
            (element) => element.center_id === center_id
          )
        )
          return { center_id, center_name };
      });
    },
    excavatorTypes() {
      if (this.selectedCenter) {
        let tempCallerTypes = this.centerData.filter((element, index) => {
          return element["center_id"] === this.selectedCenter;
        });
        return tempCallerTypes[0]["caller_types"];
      } else {
        return [];
      }
    },
    disableExcavatorTypeSelection() {
      return this.excavatorTypes.length === 0;
    },
    centerMap() {
      return this.$store.getters.centerMap;
    },
    allCentersSelected() {
      return this.centerData.length === this.companyCenters.length;
    },
    isDisabled() {
      const {
        companyCenters,
        purchaseInProgress,
        payment_method,
        isCreditCardDetailsValid,
        selectedSubscription,
      } = this;
      return (
        companyCenters.length === 0 ||
        purchaseInProgress ||
        (payment_method === "creditCard" &&
          !isCreditCardDetailsValid &&
          selectedSubscription.tier !== "0" &&
          selectedSubscription.tier !== "3")
      );
    },
  },
  watch: {
    selectedSubscription() {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      if (
        parseInt(this.selectedSubscription.price) === 0 &&
        this.paymentElement
      )
        this.$nextTick(() => {
          this.paymentElement.unmount("#card-element");
        });
      else if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        Object.keys(this.stripe).length > 0
      ) {
        const clientSecret = this.clientSecret;
        this.elements = this.stripe.elements({ clientSecret });
        this.paymentElement = this.elements.create("card", { style: style });
        this.paymentElement.mount("#card-element");
        this.paymentElement.on("change", (event) => {
          const { complete, empty } = event;
          this.isCreditCardDetailsValid = complete && !empty;
        });
      }
    },
    subscriptions() {
      if (this.$store.state.Company.company.subscription) {
        let subscription = this.subscriptions.filter((element) => {
          return (
            element.sub_name ===
            this.$store.state.Company?.company?.subscription.sub_name
          );
        });
        this.selectedSubscription = subscription[0];
        if (this.selectedSubscription?.products.storage)
          this.storage = this.selectedSubscription.products.storage.map(
            (element) => {
              element.stripe_price_id = this.priceMap[element.id]?.id;
              element.price = this.priceMap[element.id]?.unit_amount;
              return element;
            }
          );
        if (!this.selectedSubscription) {
          this.selectedSubscription =
            this.$store.state.Subscription.subscriptionProducts.subscriptions[
              this.$store.state.Subscription.subscriptionProducts.subscriptions
                .length - 1
            ];
        }
      }
    },
  },
  created() {
    if (this.$store.state.User.centers.length === 0) {
      this.fetchCentersData();
    }
    if (this.$store.state.Company.company.users < 15)
      this.users = this.$store.state.Company.company.users;
    if (this.$store.state.Company.company.subscription) {
      let subscription = this.subscriptions.filter((element) => {
        return (
          element.sub_name ===
          this.$store.state.Company?.company?.subscription.sub_name
        );
      });
      this.selectedSubscription = subscription[0];
      if (
        this.selectedSubscription &&
        this.selectedSubscription.products.storage
      )
        this.storage = this.selectedSubscription.products.storage.map(
          (element) => {
            element.stripe_price_id = this.priceMap[element.id]?.id;
            element.price = this.priceMap[element.id]?.unit_amount;
            return element;
          }
        );
      if (!this.selectedSubscription) {
        this.selectedSubscription =
          this.$store.state.Subscription.subscriptionProducts.subscriptions[
            this.$store.state.Subscription.subscriptionProducts.subscriptions
              .length - 1
          ];
      }
    }
  },
  methods: {
    changePlan() {
      this.isCreditCardDetailsValid = false;
      if (parseInt(this.selectedSubscription.price) == 0)
        this.companyCenters = [this.companyCenters[0]];
      if (this.selectedSubscription.products.storage)
        this.storage = this.selectedSubscription.products.storage.map(
          (element) => {
            element.stripe_price_id = this.priceMap[element.id]?.id;
            element.price = this.priceMap[element.id]?.unit_amount;
            return element;
          }
        );
    },
    async submit() {
      this.purchaseInProgress = true;
      let companyDetails = this.$store.state.Company.company;
      let subscription = this.subscriptions.filter((element) => {
        return element.name === this.selectedSubscription.name;
      });
      let payment = "";
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.payment_method != "invoice"
      ) {
        payment = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.paymentElement,
          billing_details: {
            name: companyDetails.name,
            email: companyDetails.email,
            address: {
              line1: companyDetails.address,
              postal_code: companyDetails.zip,
              city: companyDetails.city,
              state: companyDetails.state,
              country: "US",
            },
          },
        });
      }
      companyDetails.paymentMethod = payment;
      companyDetails.payment_method = this.payment_method;
      companyDetails.priceList = [];
      if (subscription[0])
        companyDetails.priceList.push({
          price: subscription[0].stripe_price_id,
          quantity: this.users,
        });
      if (this.companyCenters.length > 1)
        companyDetails.priceList.push({
          price: this.selectedSubscription.center_stripe_price_id,
          quantity: this.companyCenters.length - 1,
        });
      if (Object.keys(this.planStorage).length > 1)
        companyDetails.priceList.push({
          price: this.planStorage.stripe_price_id,
          quantity: 1,
        });
      companyDetails.storage = this.planStorage
        ? parseInt(this.planStorage.storage_limit)
        : 0 + 250;
      companyDetails.contactusData =
        this.$store.state.Subscription.contactusData;
      companyDetails.paymentHistory = {
        subscription_id: this.selectedSubscription.subscription_id,
        final_price:
          parseInt(
            this.selectedSubscription.price
              ? this.selectedSubscription.price * 12 * this.users
              : 0
          ) +
          parseInt(
            this.selectedSubscription.base_price
              ? this.selectedSubscription.base_price
              : 0
          ) +
          parseInt(
            this.planStorage &&
              this.planStorage.price &&
              parseInt(this.selectedSubscription.price) > 0
              ? this.planStorage.price
              : 0
          ),
      };
      companyDetails.type = this.companyCenters[0].excavator_type;
      companyDetails.centers = this.companyCenters;
      companyDetails.subscription_id = subscription[0]
        ? subscription[0].subscription_id
        : this.selectedSubscription.subscription_id;
      companyDetails.users = this.users;
      this.$store.commit("setCompanyData", companyDetails);
      delete companyDetails.subscription;
      const CompanyStatus = await this.$store.dispatch(
        "updateSubscriptionCompany",
        companyDetails
      );
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.payment_method != "invoice"
      ) {
        const result = await this.stripe.confirmCardPayment(
          CompanyStatus[0].client_secret,
          {
            payment_method: {
              type: "card",
              card: this.paymentElement,
              billing_details: {
                name: companyDetails.name,
                email: companyDetails.email,
                address: {
                  line1: companyDetails.address,
                  postal_code: companyDetails.zip,
                  city: companyDetails.city,
                  state: companyDetails.state,
                  country: "US",
                },
              },
            },
          }
        );
        if (result?.paymentIntent?.status === "succeeded") {
          this.$emit("snackbarText", "Payment successful");
          this.purchaseInProgress = false;
        }
      }
      this.$emit("updateStep");
    },
    getStorageText(item) {
      if (item < 1000) return item + " GB";
      else {
        return item / 1000 + " TB";
      }
    },
    onCenterChange() {
      this.excavatorType = null;
    },
    close() {
      this.onAddCenter = true;
      this.selectedCenter = null;
      this.excavatorType = null;
    },
    onAddCenterClicked() {
      this.onAddCenter = false;
    },
    deleteCenter(value) {
      this.deleteInProgress = true;
      let tempCenter = this.companyCenters.filter((val) => {
        return !(
          val.center_id === value.center_id &&
          val.excavator_type === value.excavator_type
        );
      });
      this.companyCenters = tempCenter;
      this.onAddCenter = true;
      this.deleteInProgress = false;
    },
    addCenter() {
      const centersObject = {};
      centersObject.center_id = this.selectedCenter;
      centersObject.excavator_type = this.excavatorType;
      centersObject.default = this.companyCenters.length === 0 ? true : false;
      let tempCallerTypes = this.centerData.filter((element, index) => {
        return element["center_id"] === this.selectedCenter;
      });
      let tempCenter = this.companyCenters.find((val) => {
        return (
          val.center_id === this.selectedCenter &&
          val.excavator_type === this.excavatorType
        );
      });
      if (!tempCenter) {
        centersObject.center_name = tempCallerTypes[0].center_name;
        centersObject.abbrev = tempCallerTypes[0].abbrev;
        centersObject.state = tempCallerTypes[0].state;
        this.companyCenters.push(centersObject);
      }
      this.selectedCenter = null;
      this.excavatorType = null;
      if (
        parseInt(this.selectedSubscription.price) === 0 &&
        this.companyCenters.length >= 1
      )
        this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
