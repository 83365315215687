<template>
  <v-container fluid class="pa-0 mb-12" fill-height>
    <v-card
      elevation="0"
      height="100%"
      width="100%"
      class="fill-height"
      :class="{
        'pa-0 grey lighten-4': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <img
            width="160"
            class="pt-7 pb-4 pt-sm-9 m-44"
            src="@/assets/images/logo.png"
            alt="Dig Logix Logo"
          />
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg mt-10 m-3"
            @click="onSignIn"
          >
            {{ $t("signIn") }}</v-btn
          >
        </v-col>
        <v-col v-if="step !== 6" cols="12" lg="10" md="11" class="pt-0">
          <progressBar :current-step="step" />
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-column"
          v-if="!$vuetify.breakpoint.mdAndUp"
        >
          <v-btn
            v-if="showBackArrow && step !== 3"
            fab
            text
            absolute
            plain
            left
            class="mt-1 ml-n2 my-auto"
            @click="updateStep(step - 1)"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <!-- <img
            width="160"
            class="pt-5 mx-auto pt-sm-9 py-xs-5"
            src="@/assets/images/logo.png"
            alt="Dig Logix Logo"
          /> -->
        </v-col>
      </v-row>
      <v-card
        :max-width="
          $vuetify.breakpoint.mdAndUp
            ? step === 2 || step === 3
              ? '75%'
              : step >= 4 && step <= 6
                ? '45%'
                : '95%'
            : '100%'
        "
        class="ma-auto rounded-lg"
        :style="[
          step >= 4 && step <= 6 ? { height: '70%' } : { height: '86%' },
        ]"
        elevation="0"
        :class="
          $vuetify.breakpoint.mdAndUp && step !== 6 ? 'pt-4 pb-8' : 'mb-8'
        "
      >
        <v-row v-if="!showSuccess" class="fill-height" no-gutters>
          <v-row no-gutters>
            <div v-if="!subscriptions">
              <v-progress-circular
                :size="70"
                :width="5"
                color="green"
                indeterminate
                class="mt-4 empty-state"
              ></v-progress-circular>
            </div>
            <v-container v-else fluid>
              <CompanySubscriptionPlan
                v-if="step === 1"
                :subscriptions="subscriptions"
                @updateStep="updateSubscriptionStep"
              />
              <CompanyInformation
                v-if="step === 2"
                :subscriptions="subscriptions"
                :selected-subscription="selectedSubscription"
                :show-signup="true"
                @submitCompanyDetails="saveCompanyDetails"
                @updateStep="updateStep"
                :show-prepopulated-data="showformData"
                @update:showPrepopulatedData="updatePopulatedDataProp"
              />
              <OrderSummary
                @updateStep="updateStep(4)"
                v-if="step === 3"
                @snackbarText="handleSnackbarEvent"
              />
              <PersonalDetail
                v-if="step == 4"
                ref="personalDetail"
                :is-invited-user="isInvitedUser"
                @updateStep="updateStep"
                @userCreated="updateStep(5)"
              />
              <MFA
                v-if="step == 5"
                @userVerified="onMFAVerification(6)"
                @updateStep="updateStep"
              />
              <SetPassword
                v-if="step == 6"
                :is-invited-user="isInvitedUser"
                @setUserPassword="updateStep(7)"
                @updateStep="updateStep"
              />
            </v-container>
          </v-row>
        </v-row>

        <confirmationMessageVue
          v-else
          :text="signupSuccessObject.text"
          :button="signupSuccessObject.button"
          :is-welcome-message="signupSuccessObject.isWelcomeMessage"
          :show-logo="false"
          @onSubmitFirstTicket="onSubmitFirstTicket"
        />
      </v-card>
    </v-card>
    <Footer :show-full-width="true" />
  </v-container>
</template>

<script>
import confirmationMessageVue from "../../molecules/ConfirmationMessage.vue";
import PersonalDetail from "./personal-detail.vue";
import CompanySubscriptionPlan from "@/components/workflow/signUpNew/CompanySubscriptionPlan";
import MFA from "../../molecules/MFA.vue";
import progressBar from "./progress-bar.vue";
import SetPassword from "./set-password";
import Footer from "../../molecules/CompanyFooter.vue";
import ERRORS from "@/static/errors.json";
import { getKeys } from "@/store/utils/utils";

import CompanyInformation from "../../molecules/CompanyInformation.vue";
import { setAuthDetails, setPermissions } from "@/store/utils/utils";
import OrderSummary from "./orderSummary.vue";
export default {
  name: "SignUpMain",
  components: {
    confirmationMessageVue,
    PersonalDetail,
    MFA,
    progressBar,
    SetPassword,
    Footer,
    CompanySubscriptionPlan,
    CompanyInformation,
    OrderSummary,
  },
  props: {
    updatedStep: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    showPrepopulatedData: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => ({
    showformData: false,
    isInvitedUser: false,
    selectedSubscription: {},
    defaultSelectedUser: null,
    step: 1,
    users: 1,
    signupSuccessObject: {
      text: {
        bodyLabel: "Congratulations! Your account has been successfully created. Click continue to start creating and managing your tickets.",
        headerLabel: "Welcome to Dig LOGiX!",
      },
      button: {
        buttonLabel: "getStarted",
        buttonColor: "#0073D1",
        buttonEvent: "onSubmitFirstTicket",
      },
      isWelcomeMessage: true,
      selectedSubscription: {},
    },
    signUpMainSnackbar: false,
    signUpMainSnackbarText: "",
  }),
  computed: {
    subscriptions() {
      const subscriptionData =
        this.$store.state.Subscription.subscriptionProducts;
      if (subscriptionData.subscriptions) {
        return this.getNewPlanDetails(subscriptionData.subscriptions);
      } else {
        return [];
      }
    },
    showSuccess() {
      return this.step === 7;
    },
    showBackArrow() {
      if (this.isInvitedUser) {
        return this.step > 3;
      } else {
        return this.step > 1 && this.step <= 6;
      }
    },
    subscriptionWithProducts() {
      console.log(this.$store.state.subscriptionProducts);
      return true;
    },
  },
  async created() {
    if (this.updatedStep != 0) {
      this.updateStep(this.updatedStep);
    }
    this.showformData = this.showPrepopulatedData;
    localStorage.clear();
    //this.fetchSubscriptiosData();
    this.fetchDataForInvitedUser();
    if (
      Object.keys(this.$store.state.Subscription.subscriptionProducts)
        .length === 0
    )
      this.fetchProductsData();
  },
  methods: {
    onSignIn() {
      this.$router.push("/signin").catch();
    },
    updatePopulatedDataProp(boolVal) {
      this.showformData = boolVal;
    },
    async saveCompanyDetails(payload) {
      let companyData = { ...payload };
      companyData.options = {
        settings: { mfa: false },
        eula: companyData.eula,
      };
      this.selectedSubscription = payload.subscription;
      companyData.paymentHistory = payload.subscription;
      companyData.users = this.users;
      this.$store.commit("setCompanyData", companyData);
      this.updateStep(3);
    },

    getNewPlanDetails(subscriptionResp) {
      if (Array.isArray(subscriptionResp)) {
        return subscriptionResp.filter((subscription) => {
          return subscription.is_new;
        });
      }
    },
    getDefaultSelectedUser() {
      if (this.subscriptions) {
        return this.subscriptions.filter((element) => {
          if (element.name === "Pro" && element.users === "2") {
            return true;
          }
        })[0];
      }
    },
    onMFAVerification() {
      this.updateStep(6);
    },
    updateStep(step) {
      this.step = step;
    },
    updateSubscriptionStep(payload, users) {
      this.selectedSubscription = payload;
      this.users = users;
      this.step = 2;
    },
    onSubmitFirstTicket() {
      setAuthDetails({
        userId: this.$store.state.User.user.user_id,
        token: this.$store.state.User.user.token
          ? this.$store.state.User.user.token
          : this.$store.state.User.token,
      });
    },
    async fetchDataForInvitedUser() {
      const urlParams = new URLSearchParams(location.search);
      const keys = getKeys(urlParams);
      if (keys.length && keys.includes("token")) {
        this.isInvitedUser = true;
        this.step = 4;
        const authDetails = {};
        for (const [key, value] of urlParams) {
          if (key === "userId") {
            authDetails.userId = value;
          }
          if (key === "token") {
            authDetails.token = value;
          }
        }
        const userData = await this.$store.dispatch(
          "getUserDataById",
          authDetails
        );
        if (
          userData.status === "error" &&
          (userData.error === ERRORS.AUTHORIZATION_REQUIRED ||
            userData.error === ERRORS.JWT_EXPIRED ||
            userData.error === ERRORS.INVALID_TOKEN ||
            userData.error.includes(ERRORS.UNEXPECTED_TOKEN))
        ) {
          this.inviteLinkInvalid = true;
        } else {
          this.$store.commit("setUserData", userData[0]);
          setPermissions(userData[0].permissions);
        }
      }
    },
    async fetchSubscriptiosData() {
      const subscriptionData = await this.$store.dispatch("getsubscriptions");
      if (subscriptionData.status !== "error") {
        let subscriptionObject = {};
        subscriptionData.forEach((element) => {
          subscriptionObject[element.subscription_id] = element;
        });
        this.$store.commit("setSubscriptionMap", subscriptionObject);
        this.$store.commit("setSubscriptions", subscriptionData);
      }
    },
    async fetchProductsData() {
      const subscriptionData = await this.$store.dispatch(
        "getSubscriptionProductsData"
      );
      if (subscriptionData.status !== "error") {
        let subscriptionObject = {};
        subscriptionData.subscriptions.forEach((element) => {
          let products = {};
          let storage = [];
          subscriptionData.products.forEach((item) => {
            let price;
            price = subscriptionData.prices.find((obj) => {
              return obj.id == item.default_price;
            });
            item.price = price;
            if (item.id === element.products.baseProduct) {
              products.baseProduct = item;
            }
            if (item.id === element.products.addOn) {
              products.addOn = item;
            }
            if (item.id === element.products.center) {
              products.center = item;
            }
            if (
              element.products.storage &&
              element.products.storage.includes(item.id)
            ) {
              storage.push(item);
            }
          });
          if (storage.length) {
            products.storage = storage;
          }
          if (Object.keys(products).length) {
            element.productsData = products;
          }
          subscriptionObject[element.subscription_id] = element;
        });
        this.$store.commit("setSubscriptionMap", subscriptionObject);
        this.$store.commit("setSubscriptionProduct", subscriptionData);
        console.log(this.$store.state.Subscription.subscriptionProducts);
      }
    },
    handleSnackbarEvent(text) {
      this.signUpMainSnackbarText = text;
      this.signUpMainSnackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/settings.scss";
.v-divider-style {
  border-width: 1.2px !important;
}

.m-44 {
  margin-left: 44%;
}
.m-3 {
  margin-right: 3%;
}
.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
