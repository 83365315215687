var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        { staticClass: "my-3" },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-n3 ml-16 my-auto",
                  attrs: {
                    fab: "",
                    text: "",
                    absolute: "",
                    plain: "",
                    left: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("updateStep", 5)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass:
                "text-h5 mx-auto grey-darken-4--text font-weight-bold",
            },
            [_vm._v(_vm._s(_vm.$t(_vm.passwordHeaderText)))]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-1" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto pa-3",
              attrs: {
                cols: "12",
                sm: "9",
                lg: _vm.passwordBodyWidth,
                md: _vm.passwordBodyWidth,
                "space-around": "",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mx-auto",
                      attrs: { cols: "12", sm: "10", md: "12", lg: "12" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto pb-2 rounded-lg",
                          attrs: { outlined: "", color: "grey lighten-4" },
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "body-2 mb-0 font-weight-medium" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("passwordMustText")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "ml-4",
                              attrs: { caption: "", "font-weight-regular": "" },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        size: "12",
                                        color: _vm.containsEightCharacters
                                          ? "success"
                                          : "grey darken-4",
                                      },
                                    },
                                    [_vm._v("mdi-checkbox-blank-circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      class: _vm.containsEightCharacters
                                        ? "success--text"
                                        : "grey-darken-4--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("charactersLengthText")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        size: "12",
                                        color: _vm.containsNumber
                                          ? "success"
                                          : "grey darken-4",
                                      },
                                    },
                                    [_vm._v("mdi-checkbox-blank-circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      class: _vm.containsNumber
                                        ? "success--text"
                                        : "grey-darken-4--text",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("numberText")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        size: "12",
                                        color:
                                          _vm.containsUppercase &&
                                          _vm.containsLowercase
                                            ? "success"
                                            : "grey darken-4",
                                      },
                                    },
                                    [_vm._v("mdi-checkbox-blank-circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.containsUppercase &&
                                        _vm.containsLowercase
                                          ? "success--text"
                                          : "grey-darken-4--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("upperLowerLettersText")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        size: "12",
                                        color: _vm.containsSpecialCharacter
                                          ? "success"
                                          : "grey darken-4",
                                      },
                                    },
                                    [_vm._v("mdi-checkbox-blank-circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      class: _vm.containsSpecialCharacter
                                        ? "success--text"
                                        : "grey-darken-4--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("specialCharacterText")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-italic ml-4 text-wrap mr-3",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("avoidDictionaryText")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    {
                      attrs: { fluid: "", "pa-0": "", "mt-5": "", "mb-9": "" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                cols: "12",
                                sm: "10",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-1",
                                attrs: {
                                  outlined: "",
                                  "hide-details": "true",
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showPassword ? "text" : "password",
                                  label: _vm.$t("enterPassword"),
                                },
                                on: {
                                  input: _vm.checkPassword,
                                  "click:append": function ($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                  },
                                  copy: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto mb-1",
                              attrs: {
                                cols: "12",
                                sm: "10",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { flex: "", align: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "flex-grow-1 pl-0" },
                                    [
                                      _c("v-progress-linear", {
                                        staticClass: "mx-auto ma-0",
                                        attrs: {
                                          "aria-label": _vm.score.value,
                                          rounded: "",
                                          height: "7",
                                          color: _vm.score.color,
                                          value: _vm.score.value,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "px-0 py-3 flex-grow-0" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-no-wrap caption font-weight-regular",
                                          class: _vm.score.color + "--text",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.score.strength)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                cols: "12",
                                sm: "10",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-1",
                                attrs: {
                                  outlined: "",
                                  "append-icon": _vm.showConfirmPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showConfirmPassword
                                    ? "text"
                                    : "password",
                                  label: _vm.$t("confirmPassword"),
                                  rules: [_vm.rules.confirmPasswordRules],
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showConfirmPassword =
                                      !_vm.showConfirmPassword
                                  },
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                  },
                                  copy: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.confirmPassword = $$v
                                  },
                                  expression: "confirmPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-auto pa-0",
                              attrs: { cols: "12", sm: "10", lg: "4", md: "4" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 text-none rounded-lg",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    disabled:
                                      !_vm.checkValidPassword ||
                                      _vm.isSubmitClicked,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(_vm.passwordButtonText)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "35%" },
          model: {
            value: _vm.isEmailSent,
            callback: function ($$v) {
              _vm.isEmailSent = $$v
            },
            expression: "isEmailSent",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { height: "50%" } },
            [
              _vm.isEmailSent
                ? _c("ConfirmationMessage", {
                    attrs: {
                      icon: _vm.emailSentObject.icon,
                      text: _vm.emailSentObject.text,
                      button: _vm.emailSentObject.button,
                      "show-logo": _vm.emailSentObject.showLogo,
                      "background-color": _vm.emailSentObject.backgroundColor,
                    },
                    on: {
                      onBackToTicket: function ($event) {
                        return _vm.$emit("setUserPassword")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }