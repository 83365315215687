<template>
  <div>
    <v-stepper
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="currentStep"
      elevation="0"
      class="transparent py-0 my-0"
    >
      <v-stepper-header class="transparent elevation-0">
        <template v-for="(step, stepIndex) in stepperSteps">
          <v-stepper-step
            :complete="currentStep > stepIndex + 1"
            :step="stepIndex + 1"
            :key="step"
            class="transparent elevation-0 py-0"
            :color="currentStep > stepIndex + 1 ? 'success' : 'primary'"
            >{{ $t(step) }}</v-stepper-step
          >
          <v-divider
            v-if="stepIndex < stepperSteps.length - 1"
            :key="step + stepIndex"
          />
        </template>
      </v-stepper-header>
    </v-stepper>
    <div v-else>
      <mobile-progress :step="currentStep - 1" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",

  components: { mobileProgress: () => import("./mobile-progress.vue") },
  props: {
    currentStep: {
      type: Number,
      default: () => 1,
    },
    incProgress: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      stepperSteps: [
        "choosePlan",
        "companyAndBilling",
        "Plan Summary",
        "personalDetails",
        "emailVerification",
        "setPassword",
      ],
    };
  },
};
</script>
<style lang="scss"></style>
