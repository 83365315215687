var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 mb-12", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "fill-height",
          class: {
            "pa-0 grey lighten-4": _vm.$vuetify.breakpoint.mdAndUp,
          },
          attrs: { elevation: "0", height: "100%", width: "100%" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row justify-space-between",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("img", {
                        staticClass: "pt-7 pb-4 pt-sm-9 m-44",
                        attrs: {
                          width: "160",
                          src: require("@/assets/images/logo.png"),
                          alt: "Dig Logix Logo",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg mt-10 m-3",
                          attrs: { color: "primary", outlined: "" },
                          on: { click: _vm.onSignIn },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("signIn")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step !== 6
                ? _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: { cols: "12", lg: "10", md: "11" },
                    },
                    [
                      _c("progressBar", {
                        attrs: { "current-step": _vm.step },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm.showBackArrow && _vm.step !== 3
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mt-1 ml-n2 my-auto",
                              attrs: {
                                fab: "",
                                text: "",
                                absolute: "",
                                plain: "",
                                left: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateStep(_vm.step - 1)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "ma-auto rounded-lg",
              class:
                _vm.$vuetify.breakpoint.mdAndUp && _vm.step !== 6
                  ? "pt-4 pb-8"
                  : "mb-8",
              style: [
                _vm.step >= 4 && _vm.step <= 6
                  ? { height: "70%" }
                  : { height: "86%" },
              ],
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.mdAndUp
                  ? _vm.step === 2 || _vm.step === 3
                    ? "75%"
                    : _vm.step >= 4 && _vm.step <= 6
                    ? "45%"
                    : "95%"
                  : "100%",
                elevation: "0",
              },
            },
            [
              !_vm.showSuccess
                ? _c(
                    "v-row",
                    { staticClass: "fill-height", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          !_vm.subscriptions
                            ? _c(
                                "div",
                                [
                                  _c("v-progress-circular", {
                                    staticClass: "mt-4 empty-state",
                                    attrs: {
                                      size: 70,
                                      width: 5,
                                      color: "green",
                                      indeterminate: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _vm.step === 1
                                    ? _c("CompanySubscriptionPlan", {
                                        attrs: {
                                          subscriptions: _vm.subscriptions,
                                        },
                                        on: {
                                          updateStep:
                                            _vm.updateSubscriptionStep,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.step === 2
                                    ? _c("CompanyInformation", {
                                        attrs: {
                                          subscriptions: _vm.subscriptions,
                                          "selected-subscription":
                                            _vm.selectedSubscription,
                                          "show-signup": true,
                                          "show-prepopulated-data":
                                            _vm.showformData,
                                        },
                                        on: {
                                          submitCompanyDetails:
                                            _vm.saveCompanyDetails,
                                          updateStep: _vm.updateStep,
                                          "update:showPrepopulatedData":
                                            _vm.updatePopulatedDataProp,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.step === 3
                                    ? _c("OrderSummary", {
                                        on: {
                                          updateStep: function ($event) {
                                            return _vm.updateStep(4)
                                          },
                                          snackbarText: _vm.handleSnackbarEvent,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.step == 4
                                    ? _c("PersonalDetail", {
                                        ref: "personalDetail",
                                        attrs: {
                                          "is-invited-user": _vm.isInvitedUser,
                                        },
                                        on: {
                                          updateStep: _vm.updateStep,
                                          userCreated: function ($event) {
                                            return _vm.updateStep(5)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.step == 5
                                    ? _c("MFA", {
                                        on: {
                                          userVerified: function ($event) {
                                            return _vm.onMFAVerification(6)
                                          },
                                          updateStep: _vm.updateStep,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.step == 6
                                    ? _c("SetPassword", {
                                        attrs: {
                                          "is-invited-user": _vm.isInvitedUser,
                                        },
                                        on: {
                                          setUserPassword: function ($event) {
                                            return _vm.updateStep(7)
                                          },
                                          updateStep: _vm.updateStep,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("confirmationMessageVue", {
                    attrs: {
                      text: _vm.signupSuccessObject.text,
                      button: _vm.signupSuccessObject.button,
                      "is-welcome-message":
                        _vm.signupSuccessObject.isWelcomeMessage,
                      "show-logo": false,
                    },
                    on: { onSubmitFirstTicket: _vm.onSubmitFirstTicket },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Footer", { attrs: { "show-full-width": true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }