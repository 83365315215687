<template>
  <div>
    <v-row
      class="mt-8 personal-details"
      v-if="$vuetify.breakpoint.mdAndUp && !isInvitedUser"
    >
      <span class="text-h5 mx-auto grey-darken-4--text font-weight-bold">{{
        $t("personalDetailsNew")
      }}</span>
    </v-row>
    <v-row class="" v-else>
      <span class="text-h5 mx-auto grey-darken-4--text font-weight-bold">{{
        $t("personalDetailsNew")
      }}</span>
    </v-row>

    <v-row class="mt-6">
      <v-col class="mx-auto" cols="12" sm="9" md="9" lg="9" space-around>
        <v-row v-if="!isInvitedUser" no-gutters>
          <v-col cols="12" sm="10" md="12" lg="12" class="mx-auto"> </v-col>
        </v-row>

        <v-form>
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="10" md="12" lg="12" class="mx-auto">
                <v-text-field
                  v-model="name"
                  :error-messages="nameErrors"
                  :label="$t('fullName')"
                  :disabled="isInvitedUser"
                  outlined
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="10" md="12" lg="12" class="mx-auto">
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  :label="$t('emailAddress')"
                  :disabled="isInvitedUser"
                  outlined
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  @keypress="isEmailAvailable = true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="10" md="12" lg="12" class="mx-auto">
                <v-text-field
                  v-model="phone"
                  :error-messages="phoneErrors"
                  :label="$t('phoneNumber')"
                  :disabled="isInvitedUser"
                  outlined
                  required
                  @input="$v.phone.$touch()"
                  @blur="$v.phone.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="10" lg="7" md="7" class="mx-auto mb-8">
                <v-btn
                  block
                  class="mr-4 text-none rounded-lg"
                  color="primary"
                  :disabled="$v.$invalid || isContinueClicked"
                  @click="submit"
                >
                  {{ $t("continue") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import states from "@/static/states.json";
import errors from "@/static/errors.json";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
);
const zip = helpers.regex("serial", /^\d{5}(-\d{4})?$/);

export default {
  name: "PersonalDetails",
  mixins: [validationMixin],
  props: {
    isInvitedUser: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    name: { required, maxLength: maxLength(80), minLength: minLength(1) },
    email: { required, email },
    phone: { required, phone },
  },

  data: () => ({
    name: "",
    email: "",
    phone: "",
    homeAddress: "",
    city: "",
    state: "",
    zip: "",
    selectState: states.state,
    checkbox: false,

    checkType: 0,
    isEmailAvailable: true,
    isContinueClicked: false,
  }),

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push(this.$t("nameLengthError"));
      !this.$v.name.required && errors.push(this.$t("nameRequiredError"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("emailValidError"));
      !this.$v.email.required && errors.push(this.$t("emailRequiredError"));
      !this.isEmailAvailable && errors.push(this.$t("emailAllreadyRegistered"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.phone && errors.push(this.$t("phoneValidError"));
      !this.$v.phone.required && errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    homeAddressErrors() {
      const errors = [];
      if (!this.$v.homeAddress.$dirty) return errors;
      !this.$v.homeAddress.maxLength &&
        errors.push(this.$t("addressLengthError"));
      !this.$v.homeAddress.required &&
        errors.push(this.$t("addressRequiredError"));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.maxLength && errors.push(this.$t("cityLengthError"));
      !this.$v.city.required && errors.push(this.$t("cityRequiredError"));
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push(this.$t("stateRequiredError"));
      return errors;
    },
    zipErrors() {
      const errors = [];
      if (!this.$v.zip.$dirty) return errors;
      !this.$v.zip.zip && errors.push(this.$t("zipValidError"));
      !this.$v.zip.required && errors.push(this.$t("zipRequiredError"));
      return errors;
    },
    user() {
      return this.$store.getters.userData;
    },
  },

  watch: {
    user() {
      if (this.isInvitedUser) {
        this.name = this.user.name;
        this.email = this.user.email;
        this.phone = this.user.phone;
        this.checkType = 2;
      }
    },
  },
  methods: {
    setProfessionalType() {
      this.checkType = 2;
      this.homeAddress = "";
      this.state = "";
      this.city = "";
      this.zip = "";
    },
    async submit() {
      this.isContinueClicked = true;
      let userInfo = {};
      if (!this.isInvitedUser) {
        userInfo = {
          name: this.name,
          email: this.email.toLowerCase(),
          phone: this.phone,
          type: "Professional Excavator",
        };
      } else {
        userInfo = this.user;
      }

      this.$store.commit("setUserData", userInfo);
      const response = await this.$store.dispatch("createUser", userInfo);
      if (response) {
        this.isContinueClicked = false;
      }
      if (response.status === "error") {
        if (response.error === errors.USER_IS_NOT_PENDING) {
          this.isEmailAvailable = false;
        }
        this.$emit("error");
      } else {
        this.$emit("userCreated");
      }
    },
  },
};
</script>

<style lang="scss">
.excavator-type-style {
  border: 1px solid #bdbdbd !important;
}
.excavator-type-style-primary {
  border: 1px solid #0073d1 !important;
}

.personal-details {
  width: 92%;
  display: flex;
  margin-left: 4%;
}

.arrow-icon {
  cursor: pointer;
  color: grey;
  margin-left: 16%;
}
</style>
