<template>
  <v-container class="pa-0 pb-16 white" fluid>
    <v-row justify="center" class="mt-2 mb-2 px-2">
      <div class="text-h5 font-weight-bold grey--text text--darken-4">
        {{ $t("choosePlan") }}
      </div>
    </v-row>
    <v-row no-gutters class="px-3"
      ><span class="my-auto">Choose Your Team Size :</span>
      <v-col cols="4" sm="4" md="1" lg="1" xl="1" class="ml-4">
        <v-select
          v-model="users"
          :items="usersList"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          required
          dense
          hide-details
          label="Users"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="!subscriptions.length" class="mt-4 empty-state">
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row justify="center">
        <v-hover
          v-for="(subscription, index) in allSubscriptions"
          :key="index"
          v-slot="{ hover }"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'timeline-darg'"
        >
          <v-card
            v-model="selectedSubscription"
            :elevation="hover ? 15 : 0"
            :width="$vuetify.breakpoint.mdAndUp ? '23%' : '90%'"
            min-width="310"
            style="height: 100%"
            rounded
            outlined
            class="text-none mt-5 mx-3"
          >
            <v-row no-gutters>
              <v-col cols="12" class="pa-0"
                ><v-card
                  :color="
                    getSubscriptionColor(subscription.subscription_id).color
                  "
                  height="10"
                  elevation="0"
                  rounded="0"
                ></v-card>
                <v-card-title>
                  <div class="grey-darken-4--text font-weight-bold h6">
                    {{ subscription.sub_name }}
                  </div>
                </v-card-title>
                <v-card-text class="pb-0 subscription-price-content">
                  <v-row>
                    <div class="text-subtitle-2 px-3">
                      {{ subscription.description }}
                    </div>
                  </v-row>
                  <v-row>
                    <v-col cols="10" align-self="end">
                      <v-card-text class="pa-0">
                        <v-row
                          no-gutters
                          class="mx-auto"
                          v-if="!subscription.is_custom"
                        >
                          <v-col cols="12" class="pa-0">
                            <v-row>
                              <v-col cols="8" class="pb-0">
                                <v-row
                                  no-gutters
                                  class="mb-2"
                                  :class="
                                    getSubscriptionPrice(subscription) === 0
                                      ? 'mt-4'
                                      : ''
                                  "
                                >
                                  <span
                                    class="text-right pr-2 grey-darken-4--text font-weight-medium text-h4"
                                    :class="
                                      getSubscriptionColor(
                                        subscription.subscription_id,
                                      ).color
                                        ? getSubscriptionColor(
                                            subscription.subscription_id,
                                          ).textColor
                                          ? getSubscriptionColor(
                                              subscription.subscription_id,
                                            ).textColor
                                          : getSubscriptionColor(
                                              subscription.subscription_id,
                                            ).color + '--text'
                                        : ''
                                    "
                                  >
                                    {{
                                      getSubscriptionPrice(subscription) === 0
                                        ? "Free"
                                        : "$" +
                                          (
                                            getSubscriptionPrice(subscription)
                                              .flat_amount / 12
                                          ).toFixed(0)
                                    }}
                                  </span>
                                  <span
                                    class="font-weight-regular subtitle grey-darken-2--text text-center pt-2"
                                  >
                                    {{
                                      getSubscriptionPrice(subscription) !== 0
                                        ? "/month"
                                        : ""
                                    }}
                                  </span>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row v-if="getSubscriptionPrice(subscription)">
                              <v-col cols="8" class="pb-0">
                                <v-row no-gutters class="mb-2">
                                  <span
                                    class="text-right pr-2 grey-darken-4--text font-weight-medium text-h6"
                                    :class="
                                      getSubscriptionColor(
                                        subscription.subscription_id,
                                      ).color
                                        ? getSubscriptionColor(
                                            subscription.subscription_id,
                                          ).textColor
                                          ? getSubscriptionColor(
                                              subscription.subscription_id,
                                            ).textColor
                                          : getSubscriptionColor(
                                              subscription.subscription_id,
                                            ).color + '--text'
                                        : ''
                                    "
                                  >
                                    {{
                                      getSubscriptionPrice(subscription) === 0
                                        ? "Free"
                                        : "$" +
                                          (
                                            getSubscriptionPrice(subscription)
                                              .unit_amount / 12
                                          ).toFixed(0)
                                    }}
                                  </span>
                                  <span
                                    class="font-weight-regular subtitle grey-darken-2--text text-center pt-1"
                                  >
                                    {{
                                      getSubscriptionPrice(subscription) !== 0
                                        ? "user/month"
                                        : ""
                                    }}
                                  </span>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="10" class="pt-0">
                                <v-row no-gutters>
                                  <span
                                    class="text-right pr-2 grey-darken-4--text font-weight-bold text-subtitle-1"
                                  >
                                    {{
                                      getSubscriptionPrice(subscription) !== 0
                                        ? "Total " +
                                          "$" +
                                          (
                                            getSubscriptionPrice(subscription)
                                              .flat_amount /
                                              12 +
                                            (getSubscriptionPrice(subscription)
                                              .unit_amount /
                                              12) *
                                              users
                                          ).toFixed(0) +
                                          " / month"
                                        : ""
                                    }}
                                  </span>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row
                          v-else-if="subscription.is_custom"
                          no-gutters
                          class="mb-4"
                        >
                          <div class="d-flex flex-column">
                            <div
                              class="grey-darken-4--text font-weight-bold text-subtitle-1"
                            >
                              <span
                                :class="
                                  getSubscriptionColor(
                                    subscription.subscription_id,
                                  ).color
                                    ? getSubscriptionColor(
                                        subscription.subscription_id,
                                      ).textColor
                                      ? getSubscriptionColor(
                                          subscription.subscription_id,
                                        ).textColor
                                      : getSubscriptionColor(
                                          subscription.subscription_id,
                                        ).color + '--text'
                                    : ''
                                "
                              >
                                Dig Safer, Manage Smarter and Empower Your
                                Excavation Operations
                              </span>
                            </div>
                          </div>
                        </v-row>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-0 pt-0"
                    v-if="
                      !subscription.is_custom &&
                      subscription.price !== 0 &&
                      subscription.name != 'Free'
                    "
                  >
                    <v-col class="pt-0"> Billed Annually </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="pa-1">
                  <v-col cols="12">
                    <v-btn
                      v-if="
                        !subscription.is_custom &&
                        (users < 15 || subscription.price === 0)
                      "
                      :disabled="users < 1"
                      block
                      color="primary"
                      class="text-none"
                      @click="submit(subscription)"
                    >
                      <span class="font-weight-regular subtitle">
                        {{ $t("selectPlan") }}
                      </span>
                    </v-btn>
                    <v-btn
                      v-else
                      :disabled="users < 1"
                      block
                      color="#F26227"
                      class="text-none white--text"
                      @click="customQuote"
                    >
                      <span class="font-weight-regular subtitle">
                        {{ $t("contactUs") }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-4">
              <v-divider></v-divider>
            </v-row>
            <v-row no-gutters class="px-4 mt-4" justify="start">
              <span
                class="text-left pr-2 grey-darken-4--text font-weight-medium text-body-2"
              >
                {{ subscription.include }}
              </span>
            </v-row>
            <v-row
              justify="center"
              class="mb-6 mx-4 mt-3"
              style="height: 100%"
              no-gutters
              :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-1'"
            >
              <v-col
                v-for="(item, i) in subscription.options"
                :key="i"
                cols="12"
                class="pa-0 pb-2"
                :class="$vuetify.breakpoint.mdAndUp ? 'ml-1' : 'ml-8'"
              >
                <v-row>
                  <v-col class="pl-3">
                    <span
                      class="text-body-1 font-weight-bold"
                      v-text="item.name"
                    ></span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-row>
    </div>
  </v-container>
</template>

<script>
const subscriptionColorMap = {
  "11111111-1111-1111-1111-111111111111": {
    color: "pink accent-2",
    textColor: "pink--text text--accent-2",
  },
  "72c22e12-924a-4a30-8924-259c20aa2e57": {
    color: "amber accent-3",
    textColor: "amber--text text--accent-3",
  },
  "78f034ed-3c39-4ea7-94aa-d1e4a65bb89e": { color: "green" },
  "36212d34-2acb-4c41-a639-e9063273ccb2": { color: "blue" },
};
export default {
  name: "CompanySubscription",
  props: {
    subscriptions: {
      type: Array,
      default: () => {
        return [
          {
            name: "Shovel Ready",
            options: [
              { name: "Ticket Management" },
              { name: "Ticket Creation" },
              { name: "Address Suggestions" },
              { name: "Single Address Ticket (Geo LOGiX Service)" },
              { name: "Cross Street Geocoding (Geo LOGiX Service)" },
              { name: "Between Street Geocoding (Geo LOGiX Service)" },
              { name: "Lat/Long Geocoding" },
              { name: "Single 811 Center Support" },
              { name: "Positive Response Status" },
              { name: "Additional Ticket Functions" },
              { name: "Subscription Management" },
              { name: "Unlimited Users" },
              { name: "MFA Opt-In" },
              { name: "Notifications" },
              { name: "Ticket Details - Ticket Overview" },
              {
                name: "Ticket Details- Work Area Polygon Views (Geo LOGiX Service)",
              },
              {
                name: "Ticket Details – Attachments and Images (up to 250GB Free)",
              },
            ],
            price: 0,
            include: "Free Basic Ticket Creation and Management",
            is_custom: false,
            color: "pink accent-2",
            textColor: "pink--text text--accent-2",
          },
          {
            name: "Backhoe",
            options: [
              { name: "Ticket Approvals (Internal Ticket Management)" },
              {
                name: "Ticket Detail - Internal notes (Internal Ticket Management)",
              },
              { name: "Ticket Scheduling" },
            ],
            price: 120,
            include:
              "Everything in Shovel Ready, plus Scheduling and Workflow Management",
            is_custom: false,
            color: "yellow",
          },
          {
            name: "Bulldozer",
            options: [
              { name: "Ticket Approvals (Internal Ticket Management)" },
              {
                name: "Ticket Detail - Internal notes (Internal Ticket Management)",
              },
              { name: "Ticket Scheduling" },
              { name: "Project and Task Management" },
              { name: "Project Management – 811 Ticket Association" },
              { name: "Ticket Map Dashboard (Geo LOGiX Service)" },
              {
                name: "Ticket Map Dashboard with Near Ticket (Geo LOGiX Service)",
              },
              { name: "Enhanced Alerting" },
            ],
            price: 240,
            include: "Everything in Backhoe, plus Project Management",
            is_custom: false,
            color: "green",
          },
          {
            name: "Earthmover",
            options: [
              { name: "Ticket Approvals (Internal Ticket Management)" },
              {
                name: "Ticket Detail - Internal notes (Internal Ticket Management)",
              },
              { name: "Ticket Scheduling" },
              { name: "Project and Task Management" },
              { name: "Project Management – 811 Ticket Association" },
              { name: "Ticket Map Dashboard (Geo LOGiX Service)" },
              {
                name: "Ticket Map Dashboard with Near Ticket (Geo LOGiX Service)",
              },
              { name: "Enhanced Alerting" },
              { name: "Unlimited 811 centers" },
              { name: "Unlimited Users" },
            ],
            price: 0,
            include:
              "Everything in Bull Dozer, plus Unlimited 811 centers and Users",
            is_custom: true,
            color: "blue",
          },
        ];
      },
    },
  },
  data() {
    return {
      selectedSubscription: -1,
      selectedNumberOfUsers: "1",
      allSubscriptions: [],
      users: 1,
      usersList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      ],
    };
  },
  watch: {
    subscriptions() {
      this.allSubscriptions = this.subscriptions;
    },
    allSubscriptions() {
      this.$nextTick(() => {
        this.adjustColumnHeight();
      });
    },
    users() {
      if (this.users >= 15) {
        this.$router.push("/contact-us").catch();
      }
    },
  },
  created() {
    this.allSubscriptions = this.subscriptions;
    this.$nextTick(() => {
      this.adjustColumnHeight();
    });
  },
  methods: {
    async submit(selectedSubscription) {
      this.$emit("updateStep", selectedSubscription, this.users);
    },
    customQuote() {
      this.$router.push("/contact-us").catch();
    },
    adjustColumnHeight() {
      const dividerAfterElements = document.querySelectorAll(".timeline-darg");
      dividerAfterElements.forEach((element) => {
        if ((element, element.parentElement.parentElement.offsetWidth > 1300))
          element.style.minHeight = `${
            element.parentElement.parentElement.offsetHeight - 20
          }px`;
        else if (
          (element, element.parentElement.parentElement.offsetWidth > 1000)
        )
          element.style.minHeight = `${
            element.parentElement.parentElement.offsetHeight / 2
          }px`;
      });
    },
    onchangeUsers(subscription) {
      this.allSubscriptions.forEach((element) => {
        if (
          subscription.subscription_id == element.subscription_id &&
          subscription.name === "Free" &&
          subscription.users >= 5
        )
          element.users = 5;
      });

      if (subscription.name === "Free" && subscription.users >= 5) {
        const temp = this.allSubscriptions;
        this.allSubscriptions = [];
        this.$nextTick(() => {
          this.allSubscriptions = temp;
        });
      }
    },
    getSubscriptionColor(subscription_id) {
      return subscriptionColorMap[subscription_id];
    },
    getSubscriptionPrice(subscription) {
      const productsData = subscription.productsData;
      if (productsData && Object.keys(productsData)) {
        return {
          flat_amount:
            productsData.baseProduct?.price?.tiers[0].flat_amount / 100,
          unit_amount:
            productsData.baseProduct?.price?.tiers[0].unit_amount / 100,
        };
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  height: 100px;
  word-break: normal;
  align-items: normal;
}
.subscription-price-content {
  min-height: 206px;
}
.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
