var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-stepper",
            {
              staticClass: "transparent py-0 my-0",
              attrs: { elevation: "0" },
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "transparent elevation-0" },
                [
                  _vm._l(_vm.stepperSteps, function (step, stepIndex) {
                    return [
                      _c(
                        "v-stepper-step",
                        {
                          key: step,
                          staticClass: "transparent elevation-0 py-0",
                          attrs: {
                            complete: _vm.currentStep > stepIndex + 1,
                            step: stepIndex + 1,
                            color:
                              _vm.currentStep > stepIndex + 1
                                ? "success"
                                : "primary",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(step)))]
                      ),
                      stepIndex < _vm.stepperSteps.length - 1
                        ? _c("v-divider", { key: step + stepIndex })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            [_c("mobile-progress", { attrs: { step: _vm.currentStep - 1 } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }