var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "0", width: "100%" } },
    [
      _c(
        "v-card-text",
        { class: _vm.$vuetify.breakpoint.mdAndUp ? "" : "px-0" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", xl: "6", lg: "6", sm: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        elevation: _vm.$vuetify.breakpoint.mdAndUp ? 2 : 0,
                      },
                    },
                    [
                      _c("v-card-title", { staticClass: "px-7" }, [
                        _vm._v(" Service Plan "),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "my-4 px-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("span", { staticClass: "my-auto" }, [
                                _vm._v(_vm._s(_vm.$t("plan")) + " "),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "6", md: "5", lg: "5" },
                                },
                                [
                                  _vm.selectedSubscription.is_custom
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-subtitle-1 font-weight-medium",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedSubscription.sub_name
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.selectedSubscription.is_custom
                                    ? _c("v-select", {
                                        class: _vm.$vuetify.breakpoint.mdAndUp
                                          ? "ml-8"
                                          : "ml-4",
                                        attrs: {
                                          items: _vm.subscriptions,
                                          label: "",
                                          "item-text": "sub_name",
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true,
                                          },
                                          dense: "",
                                          "hide-details": "",
                                          outlined: "",
                                          "return-object": "",
                                        },
                                        on: { change: _vm.changePlan },
                                        model: {
                                          value: _vm.selectedSubscription,
                                          callback: function ($$v) {
                                            _vm.selectedSubscription = $$v
                                          },
                                          expression: "selectedSubscription",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("span", { staticClass: "my-auto" }, [
                                _vm._v("Choose Your Team Size "),
                              ]),
                              _c(
                                "v-tooltip",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    right: !_vm.$vuetify.breakpoint.smAndDown,
                                    bottom: _vm.$vuetify.breakpoint.smAndDown,
                                    "max-width": "250",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dark: "",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " mdi-information-outline "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "maximum of 14 users may be selected, if they want to add more users they may do so after the company is created from the company settings screen"
                                    ),
                                  ]),
                                ]
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    cols: "4",
                                    sm: "4",
                                    md: "5",
                                    lg: "5",
                                    xl: "5",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    class: _vm.$vuetify.breakpoint.mdAndUp
                                      ? "ml-8"
                                      : "ml-4",
                                    attrs: {
                                      items: _vm.usersList,
                                      outlined: "",
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true,
                                      },
                                      required: "",
                                      dense: "",
                                      "hide-details": "",
                                      label: "users",
                                    },
                                    model: {
                                      value: _vm.users,
                                      callback: function ($$v) {
                                        _vm.users = $$v
                                      },
                                      expression: "users",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-title",
                            { staticClass: "grey--text text--darken-4" },
                            [_vm._v(" Additional Services ")]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-5",
                                  attrs: { justify: "end" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "excavator-type-style mt-6",
                                      attrs: {
                                        width: _vm.$vuetify.breakpoint.mdAndUp
                                          ? "98%"
                                          : "98%",
                                        elevation: "0",
                                        color: "#FAFAFA",
                                        disabled:
                                          _vm.selectedSubscription.price == 0 &&
                                          _vm.companyCenters.length >= 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "font-weight-medium body-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("add811Center")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-container",
                                        { staticClass: "px-0" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "px-3",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.select811CenterOptions,
                                                      outlined: "",
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      required: "",
                                                      label:
                                                        _vm.$t("811Center"),
                                                      "item-text":
                                                        "center_name",
                                                      "item-value": "center_id",
                                                      disabled:
                                                        (_vm
                                                          .selectedSubscription
                                                          .price == 0 &&
                                                          _vm.companyCenters
                                                            .length >= 1) ||
                                                        _vm.allCentersSelected,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.onCenterChange,
                                                      blur: _vm.onCenterChange,
                                                    },
                                                    model: {
                                                      value: _vm.selectedCenter,
                                                      callback: function ($$v) {
                                                        _vm.selectedCenter = $$v
                                                      },
                                                      expression:
                                                        "selectedCenter",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "px-3",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.excavatorTypes,
                                                      disabled:
                                                        _vm.disableExcavatorTypeSelection,
                                                      outlined: "",
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      required: "",
                                                      label:
                                                        _vm.$t("excavatorType"),
                                                      "item-value": "text",
                                                    },
                                                    model: {
                                                      value: _vm.excavatorType,
                                                      callback: function ($$v) {
                                                        _vm.excavatorType = $$v
                                                      },
                                                      expression:
                                                        "excavatorType",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    "no-gutters": "",
                                                    justify: "end",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mr-4 text-none rounded-lg",
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          !_vm.selectedCenter ||
                                                          !_vm.excavatorType,
                                                      },
                                                      on: {
                                                        click: _vm.addCenter,
                                                      },
                                                    },
                                                    [_vm._v(" Add ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.$vuetify.breakpoint.mdAndUp &&
                              _vm.companyCenters.length > 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pb-0 caption font-weight-medium grey--text text--darken-1",
                                          class: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "pl-0"
                                            : "pl-7",
                                          attrs: {
                                            cols: "12",
                                            xl: "4",
                                            md: "4",
                                            lg: "4",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("811Center")))]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pb-0 caption font-weight-medium grey--text text--darken-1",
                                          class: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "pa-0"
                                            : null,
                                          attrs: {
                                            cols: "12",
                                            xl: "4",
                                            md: "4",
                                            lg: "4",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("excavatorType"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center pb-0 caption font-weight-medium grey--text text--darken-1",
                                          class: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "pa-0"
                                            : null,
                                          attrs: {
                                            cols: "12",
                                            xl: "4",
                                            md: "4",
                                            lg: "4",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("delete")))]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$vuetify.breakpoint.mdAndUp &&
                              _vm.companyCenters.length > 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          class: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "px-0"
                                            : null,
                                          attrs: {
                                            cols: "12",
                                            xl: "12",
                                            md: "12",
                                            lg: "12",
                                          },
                                        },
                                        [_c("v-divider")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.companyCenters,
                                function (value, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _vm.$vuetify.breakpoint.mdAndUp
                                        ? _c(
                                            "v-row",
                                            { staticClass: "mx-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pb-0 caption pl-4",
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "4",
                                                    md: "4",
                                                    lg: "4",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        value.center_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pb-0 caption pl-2",
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "4",
                                                    md: "4",
                                                    lg: "4",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(value.excavator_type)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pb-0 text-center caption pl-2",
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "4",
                                                    md: "4",
                                                    lg: "4",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        "aria-label": "delete",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteCenter(
                                                            value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.$vuetify.breakpoint.smAndDown
                                        ? _c(
                                            "v-row",
                                            { staticClass: "mx-0 px-0 mt-3" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "10" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "pb-0 caption grey--text text--darken-4 px-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            value.center_name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "pb-0 caption pa-0 grey--text text--darken-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          value.excavator_type
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pb-0 text-center caption pa-0 grey--text text--darken-4",
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        "aria-label": "delete",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteCenter(
                                                            value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-0 px-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mx-0 pl-0",
                                              class: _vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "px-0"
                                                : null,
                                              attrs: {
                                                cols: "12",
                                                xl: "12",
                                                md: "12",
                                                lg: "12",
                                              },
                                            },
                                            [_c("v-divider")],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _vm.selectedSubscription.price > 0
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mt-2",
                                  class: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "pl-6 pr-4"
                                    : "pl-4 pr-2",
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { elevation: "0", width: "80" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption grey--text text--darken-1 my-auto pa-0",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "additionalAttachmentsStorage"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "5", lg: "5" } },
                                    [
                                      _c("v-select", {
                                        class: _vm.$vuetify.breakpoint.mdAndUp
                                          ? "ml-4"
                                          : "ml-0",
                                        attrs: {
                                          items: _vm.storage,
                                          placeholder:
                                            _vm.planStorage &&
                                            Object.keys(_vm.planStorage)
                                              .length > 0
                                              ? ""
                                              : "Select",
                                          "item-text": "storage_limit",
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true,
                                          },
                                          dense: "",
                                          "hide-details": "",
                                          outlined: "",
                                          "return-object": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStorageText(
                                                          data.item
                                                            .storage_limit
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "selection",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStorageText(
                                                          item.storage_limit
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          711405729
                                        ),
                                        model: {
                                          value: _vm.planStorage,
                                          callback: function ($$v) {
                                            _vm.planStorage = $$v
                                          },
                                          expression: "planStorage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-card-title",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectedSubscription.price > 0,
                                  expression: "selectedSubscription.price > 0",
                                },
                              ],
                              staticClass: "grey--text text--darken-4 mt-4",
                            },
                            [_vm._v(" Payment ")]
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectedSubscription.price > 0,
                                  expression: "selectedSubscription.price > 0",
                                },
                              ],
                              staticClass: "mt-0",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0",
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.payment_method,
                                        callback: function ($$v) {
                                          _vm.payment_method = $$v
                                        },
                                        expression: "payment_method",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "100%",
                                            width: "100%",
                                            elevation: "0",
                                            rounded: "lg",
                                            outlined: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c("v-radio", {
                                                attrs: { value: "creditCard" },
                                              }),
                                              _vm._v(" Credit Card "),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    height: "100%",
                                                    width: "100%",
                                                    elevation: "0",
                                                    rounded: "lg",
                                                    outlined: "",
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "ml-2 my-1",
                                                    attrs: {
                                                      id: "card-element",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-4" : "",
                  attrs: { cols: "12", md: "6", xl: "6", lg: "6", sm: "12" },
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        elevation: _vm.$vuetify.breakpoint.mdAndUp ? 2 : 0,
                      },
                    },
                    [
                      _c("v-card-title", [_vm._v(" Order Summary ")]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "text-subtitle-1 font-weight-medium",
                              attrs: { "no-gutters": "" },
                            },
                            [_vm._v(" Subscription ")]
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v(" Plan Cost "),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm.selectedSubscription.base_price
                                          ? _vm.selectedSubscription.base_price
                                          : 0
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.users) +
                                    " users x $" +
                                    _vm._s(
                                      _vm.selectedSubscription.price
                                        ? _vm.selectedSubscription.price
                                        : 0
                                    ) +
                                    " x 12 months "
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm.selectedSubscription.price
                                          ? _vm.selectedSubscription.price *
                                              12 *
                                              _vm.users
                                          : 0
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-subtitle-1 font-weight-medium mt-2",
                              attrs: { "no-gutters": "" },
                            },
                            [_vm._v(" Centers ")]
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.companyCenters.length == 0
                                          ? 0
                                          : _vm.companyCenters.length - 1
                                      ) +
                                      " centers x $" +
                                      _vm._s(
                                        _vm.selectedSubscription
                                          .center_stripe_unit_amount / 12
                                          ? _vm.selectedSubscription
                                              .center_stripe_unit_amount / 12
                                          : 0
                                      ) +
                                      " x 12 months "
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        right:
                                          !_vm.$vuetify.breakpoint.smAndDown,
                                        bottom:
                                          _vm.$vuetify.breakpoint.smAndDown,
                                        "max-width": "250",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        dark: "",
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-information-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " You can add one center for free. Additional centers are available for a nominal price. "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm.selectedSubscription
                                          .center_stripe_unit_amount
                                          ? _vm.selectedSubscription
                                              .center_stripe_unit_amount *
                                              (_vm.companyCenters.length > 0
                                                ? _vm.companyCenters.length - 1
                                                : 0)
                                          : 0
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.planStorage &&
                          Object.keys(_vm.planStorage).length > 0 &&
                          _vm.selectedSubscription.price > 0
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "text-subtitle-1 font-weight-medium mt-2",
                                  attrs: { "no-gutters": "" },
                                },
                                [_vm._v(" Attachments ")]
                              )
                            : _vm._e(),
                          _vm.planStorage &&
                          Object.keys(_vm.planStorage).length > 0 &&
                          _vm.selectedSubscription.price > 0
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getStorageText(
                                            _vm.planStorage.storage_limit
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-end",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm._v(
                                        " $" +
                                          _vm._s(_vm.planStorage.price / 100) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [_c("v-divider")],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "my-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-subtitle-1 font-weight-medium",
                                  attrs: { cols: "6" },
                                },
                                [_vm._v(" Total Due Today ")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        parseInt(
                                          _vm.selectedSubscription.price
                                            ? _vm.selectedSubscription.price *
                                                12 *
                                                _vm.users
                                            : 0
                                        ) +
                                          parseInt(
                                            _vm.selectedSubscription.base_price
                                              ? _vm.selectedSubscription
                                                  .base_price
                                              : 0
                                          ) +
                                          parseInt(
                                            _vm.planStorage &&
                                              _vm.planStorage.price &&
                                              _vm.selectedSubscription.price > 0
                                              ? _vm.planStorage.price / 100
                                              : 0
                                          ) +
                                          parseInt(
                                            _vm.selectedSubscription
                                              .center_stripe_unit_amount
                                              ? _vm.selectedSubscription
                                                  .center_stripe_unit_amount *
                                                  (_vm.companyCenters.length > 0
                                                    ? _vm.companyCenters
                                                        .length - 1
                                                    : 0)
                                              : 0
                                          )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "text-subtitle-1 font-weight-medium",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _vm._v(
                                " Your subscription will renew annually, and you'll be charged this amount unless you make changes or cancel "
                              ),
                            ]
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "my-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-subtitle-1 font-weight-medium",
                                  attrs: { cols: "6" },
                                },
                                [_vm._v(" Plan end date ")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "6" },
                                },
                                [_vm._v(" " + _vm._s(_vm.planEndDate) + " ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-4 mb-8",
                              staticStyle: { width: "10rem", margin: "auto" },
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none rounded-lg",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [
                                  _vm._v(" Purchase "),
                                  _vm.purchaseInProgress
                                    ? _c("v-progress-circular", {
                                        staticClass: "ml-2",
                                        attrs: {
                                          indeterminate: "",
                                          size: "20",
                                          color: "white",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }