var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 pb-16 white", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-2 mb-2 px-2", attrs: { justify: "center" } },
        [
          _c(
            "div",
            {
              staticClass: "text-h5 font-weight-bold grey--text text--darken-4",
            },
            [_vm._v(" " + _vm._s(_vm.$t("choosePlan")) + " ")]
          ),
        ]
      ),
      _c(
        "v-row",
        { staticClass: "px-3", attrs: { "no-gutters": "" } },
        [
          _c("span", { staticClass: "my-auto" }, [
            _vm._v("Choose Your Team Size :"),
          ]),
          _c(
            "v-col",
            {
              staticClass: "ml-4",
              attrs: { cols: "4", sm: "4", md: "1", lg: "1", xl: "1" },
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.usersList,
                  outlined: "",
                  "menu-props": { bottom: true, offsetY: true },
                  required: "",
                  dense: "",
                  "hide-details": "",
                  label: "Users",
                },
                model: {
                  value: _vm.users,
                  callback: function ($$v) {
                    _vm.users = $$v
                  },
                  expression: "users",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.subscriptions.length
        ? _c(
            "div",
            { staticClass: "mt-4 empty-state" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "green",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.allSubscriptions, function (subscription, index) {
                  return _c("v-hover", {
                    key: index,
                    class: _vm.$vuetify.breakpoint.smAndDown
                      ? ""
                      : "timeline-darg",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var hover = ref.hover
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "text-none mt-5 mx-3",
                                  staticStyle: { height: "100%" },
                                  attrs: {
                                    elevation: hover ? 15 : 0,
                                    width: _vm.$vuetify.breakpoint.mdAndUp
                                      ? "23%"
                                      : "90%",
                                    "min-width": "310",
                                    rounded: "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.selectedSubscription,
                                    callback: function ($$v) {
                                      _vm.selectedSubscription = $$v
                                    },
                                    expression: "selectedSubscription",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("v-card", {
                                            attrs: {
                                              color: _vm.getSubscriptionColor(
                                                subscription.subscription_id
                                              ).color,
                                              height: "10",
                                              elevation: "0",
                                              rounded: "0",
                                            },
                                          }),
                                          _c("v-card-title", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grey-darken-4--text font-weight-bold h6",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      subscription.sub_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "pb-0 subscription-price-content",
                                            },
                                            [
                                              _c("v-row", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-subtitle-2 px-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          subscription.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "10",
                                                        "align-self": "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          !subscription.is_custom
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mx-auto",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "pb-0",
                                                                              attrs:
                                                                                {
                                                                                  cols: "8",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mb-2",
                                                                                  class:
                                                                                    _vm.getSubscriptionPrice(
                                                                                      subscription
                                                                                    ) ===
                                                                                    0
                                                                                      ? "mt-4"
                                                                                      : "",
                                                                                  attrs:
                                                                                    {
                                                                                      "no-gutters":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-right pr-2 grey-darken-4--text font-weight-medium text-h4",
                                                                                      class:
                                                                                        _vm.getSubscriptionColor(
                                                                                          subscription.subscription_id
                                                                                        )
                                                                                          .color
                                                                                          ? _vm.getSubscriptionColor(
                                                                                              subscription.subscription_id
                                                                                            )
                                                                                              .textColor
                                                                                            ? _vm.getSubscriptionColor(
                                                                                                subscription.subscription_id
                                                                                              )
                                                                                                .textColor
                                                                                            : _vm.getSubscriptionColor(
                                                                                                subscription.subscription_id
                                                                                              )
                                                                                                .color +
                                                                                              "--text"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.getSubscriptionPrice(
                                                                                              subscription
                                                                                            ) ===
                                                                                              0
                                                                                              ? "Free"
                                                                                              : "$" +
                                                                                                  (
                                                                                                    _vm.getSubscriptionPrice(
                                                                                                      subscription
                                                                                                    )
                                                                                                      .flat_amount /
                                                                                                    12
                                                                                                  ).toFixed(
                                                                                                    0
                                                                                                  )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "font-weight-regular subtitle grey-darken-2--text text-center pt-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.getSubscriptionPrice(
                                                                                              subscription
                                                                                            ) !==
                                                                                              0
                                                                                              ? "/month"
                                                                                              : ""
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm.getSubscriptionPrice(
                                                                        subscription
                                                                      )
                                                                        ? _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  staticClass:
                                                                                    "pb-0",
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "8",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mb-2",
                                                                                      attrs:
                                                                                        {
                                                                                          "no-gutters":
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-right pr-2 grey-darken-4--text font-weight-medium text-h6",
                                                                                          class:
                                                                                            _vm.getSubscriptionColor(
                                                                                              subscription.subscription_id
                                                                                            )
                                                                                              .color
                                                                                              ? _vm.getSubscriptionColor(
                                                                                                  subscription.subscription_id
                                                                                                )
                                                                                                  .textColor
                                                                                                ? _vm.getSubscriptionColor(
                                                                                                    subscription.subscription_id
                                                                                                  )
                                                                                                    .textColor
                                                                                                : _vm.getSubscriptionColor(
                                                                                                    subscription.subscription_id
                                                                                                  )
                                                                                                    .color +
                                                                                                  "--text"
                                                                                              : "",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getSubscriptionPrice(
                                                                                                  subscription
                                                                                                ) ===
                                                                                                  0
                                                                                                  ? "Free"
                                                                                                  : "$" +
                                                                                                      (
                                                                                                        _vm.getSubscriptionPrice(
                                                                                                          subscription
                                                                                                        )
                                                                                                          .unit_amount /
                                                                                                        12
                                                                                                      ).toFixed(
                                                                                                        0
                                                                                                      )
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "font-weight-regular subtitle grey-darken-2--text text-center pt-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getSubscriptionPrice(
                                                                                                  subscription
                                                                                                ) !==
                                                                                                  0
                                                                                                  ? "user/month"
                                                                                                  : ""
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "pt-0",
                                                                              attrs:
                                                                                {
                                                                                  cols: "10",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "no-gutters":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-right pr-2 grey-darken-4--text font-weight-bold text-subtitle-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.getSubscriptionPrice(
                                                                                              subscription
                                                                                            ) !==
                                                                                              0
                                                                                              ? "Total " +
                                                                                                  "$" +
                                                                                                  (
                                                                                                    _vm.getSubscriptionPrice(
                                                                                                      subscription
                                                                                                    )
                                                                                                      .flat_amount /
                                                                                                      12 +
                                                                                                    (_vm.getSubscriptionPrice(
                                                                                                      subscription
                                                                                                    )
                                                                                                      .unit_amount /
                                                                                                      12) *
                                                                                                      _vm.users
                                                                                                  ).toFixed(
                                                                                                    0
                                                                                                  ) +
                                                                                                  " / month"
                                                                                              : ""
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : subscription.is_custom
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mb-4",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "grey-darken-4--text font-weight-bold text-subtitle-1",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                _vm.getSubscriptionColor(
                                                                                  subscription.subscription_id
                                                                                )
                                                                                  .color
                                                                                  ? _vm.getSubscriptionColor(
                                                                                      subscription.subscription_id
                                                                                    )
                                                                                      .textColor
                                                                                    ? _vm.getSubscriptionColor(
                                                                                        subscription.subscription_id
                                                                                      )
                                                                                        .textColor
                                                                                    : _vm.getSubscriptionColor(
                                                                                        subscription.subscription_id
                                                                                      )
                                                                                        .color +
                                                                                      "--text"
                                                                                  : "",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Dig Safer, Manage Smarter and Empower Your Excavation Operations "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              !subscription.is_custom &&
                                              subscription.price !== 0 &&
                                              subscription.name != "Free"
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "mt-0 pt-0",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "pt-0" },
                                                        [
                                                          _vm._v(
                                                            " Billed Annually "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            { staticClass: "pa-1" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  !subscription.is_custom &&
                                                  (_vm.users < 15 ||
                                                    subscription.price === 0)
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-none",
                                                          attrs: {
                                                            disabled:
                                                              _vm.users < 1,
                                                            block: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.submit(
                                                                subscription
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-regular subtitle",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "selectPlan"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-none white--text",
                                                          attrs: {
                                                            disabled:
                                                              _vm.users < 1,
                                                            block: "",
                                                            color: "#F26227",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.customQuote,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-regular subtitle",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "contactUs"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "px-4",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [_c("v-divider")],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "px-4 mt-4",
                                      attrs: {
                                        "no-gutters": "",
                                        justify: "start",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-left pr-2 grey-darken-4--text font-weight-medium text-body-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(subscription.include) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-6 mx-4 mt-3",
                                      class: _vm.$vuetify.breakpoint.mdAndUp
                                        ? ""
                                        : "ml-1",
                                      staticStyle: { height: "100%" },
                                      attrs: {
                                        justify: "center",
                                        "no-gutters": "",
                                      },
                                    },
                                    _vm._l(
                                      subscription.options,
                                      function (item, i) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: i,
                                            staticClass: "pa-0 pb-2",
                                            class: _vm.$vuetify.breakpoint
                                              .mdAndUp
                                              ? "ml-1"
                                              : "ml-8",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { staticClass: "pl-3" },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "text-body-1 font-weight-bold",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.name
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }